<template>
  <certificate-preview
     :certificate="certificate"
     role="партнер"
  />
</template>

<script>
// @ is an alias to /src
import { CertificatePreview } from "@/components";
import { mapActions, mapState } from 'vuex';

export default {
  name: "DealerOffeceCertificatePreview",
  components: {
    CertificatePreview
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions('certificates', [
      "fetchAddressCertificate",
      "fetchDealerIdCertificateById",
      "fetchAddressCertificateByKey"
    ])
    
  },
  computed: {
    ...mapState('certificates', {
      certificate: state => { return {
        certificateName: state.addressCertificate.spotName,
        legalAdress: state.addressCertificate.dealerName,
        address: state.addressCertificate.spotAddress,
        certificateImage: state.addressCertificate.certificateImage,
        isShowManufacturerInfoInCertificate: state.addressCertificate.isShowManufacturerInfoInCertificate,
        manufacturerName: state.addressCertificate.manufacturerName,
        isRevoked: state.addressCertificate.isRevoked,
      }}
    })
  },
  created() {
    if (this.$route.params.certificateId)
      this.fetchDealerIdCertificateById({id: this.$route.params.id, certificateId: this.$route.params.certificateId})    
    else if (this.$route.query.Key)
      this.fetchAddressCertificateByKey(this.$route.query.Key)    
    else
      this.fetchAddressCertificate(this.$route.params.id)    
  },

};
</script>

<style lang="scss" scoped></style>